<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item v-for="(item, index) in menu" :key="index"  @click="emitRefClick(item.link)">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <a class="text-decoration-none">
            <v-icon small color="primary">mdi-email-outline</v-icon>
            team@arbitlabs.com
          </a>
        </div>
      </template>
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar
    app
    flat
    height="120"
    color='background'>
      <div class="d-flex flex-column flex-grow-1 fill-height">
        <v-container class="px-0 py-0">
          <div class="px-sm-2 body-2 d-flex align-center justify-space-between">
            <div>
              <a href="#" class="text-decoration-none d-none d-sm-inline-block">
                <v-icon small color="primary">mdi-email-outline</v-icon>
                team@arbitlabs.com
              </a>
            </div>
            <div>
              <v-btn href="https://discordapp.com/users/arbfr#6872"  icon small>
                <v-icon small>mdi-discord</v-icon>
              </v-btn>
              <v-btn href="https://twitter.com/frdclee" icon small>
                <v-icon small>mdi-twitter</v-icon>
              </v-btn>
              <v-btn href="mailto:team@arbitlabs.com" icon small>
                <v-icon small>mdi-email</v-icon>
              </v-btn>
            </div>
          </div>
          <v-divider></v-divider>
        </v-container>

        <v-container class="py-1 px-0 px-sm-2 d-flex flex-grow-1 align-center">
          <router-link to="#" class="d-flex align-center text-decoration-none mr-2">
            <img class="mr-2" :src="require('@/assets/images/arbitlogo.png')" height="40"/>
            <span class="font-weight-bold mt-2" style="font-size:28px; color:black">
          Arbit Labs
            </span>
          </router-link>

          <v-spacer></v-spacer>

          <div class="d-none d-md-block">
            <v-btn
              v-for="(item, index) in menu"
              :key="index"
              large
              text
              class="mx-1"
              @click="emitRefClick(item.link)"
            >
              {{ item.title }}
            </v-btn>
          </div>
        </v-container>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { menu2 as menu } from './menus.js'

export default {
  data() {

    return {
      drawer: null,
      menu: menu
    }
  },
  methods: {
    emitRefClick(link) {
      this.$emit('go-to-ref', link)

    }
  }
}
</script>

<style>
.drawer-button {
  position: fixed;
  top: 102px;
  left: -22px;
  z-index: 6;
}
</style>
