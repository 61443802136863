<template>
  <v-container class="py-2 py-md-2">
        <div style="width: 80px; height: 4px" class="mb-3 secondary" />

    <v-row>
      <v-col>
        <h2 class="mt-xl-2 font-weight-medium">Explore your ideas with our support</h2>
        <h2 class="text-h6 text-sm-h6 mt-3 font-weight-light">Within rapidly developing technologies of blockchain ecosystems, we help you discover the right tools and strategies with our data and trading solutions.
          Our services include:
        </h2>
  </v-col>
 
  <ServicesCards />    
  </v-row>
  </v-container>
</template>

<script>
import ServicesCards from '@/components/ServicesCards.vue'

export default {
  components: {
    ServicesCards
  }
}
</script>
