<template>
  <v-sheet color="transparent">
    <v-container>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
        >
          <v-card class="py-4 px-2">
            <v-responsive max-width="300" height="200" class="mx-auto text-center">
              <div>
                <v-icon large color="secondary">{{ item.icon }}</v-icon>
              </div>
              <div class="text-h6 mt-3">{{ item.title }}</div>
              <div class="font-weight-regular mt-2">{{ item.description }}</div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-bitcoin',
        title: 'Market Making',
        description: 'Market-neutral market-making with algorithm specific to each currency. Provide stable liquidity at low cost.'
      }, 
      {
        icon: 'mdi-alpha',
        title: 'Algorithmic Trading',
        description: 'Automation and psuedo-arbitrage, from backtesting to strategy tune-ups. Implementation is in the details.'
      }, {
        icon: 'mdi-web',
        title: 'Web3 and API Service',
        description: 'Build your data infrastructure, integrate external data sources and on-chain insights into your project.'
      },  {
        icon: 'mdi-tractor',
        title: 'DeFi Advisory and Management',
        description: 'Quantitative management of staking and yield farming. Advisory service for stablecoin, volatile or new products.'
      }, {
        icon: 'mdi-chart-box-outline',
        title: 'Data Science',
        description: 'Predictive modeling, NFT valuation, analytics and visualization, our team has the expertise.'
      },
      
      {
        icon: 'mdi-lifebuoy',
        title: 'Dedicated Support',
        description: 'Customized and responsive support to get your project ready for a rapidly evolving environment.'
      }]
    }
  }
}
</script>
