<template>
  <v-sheet color="transparent">

    <Toolbar @go-to-ref="scrollToRef" />
    <div ref='about'>
    <About />
    </div>
    <div ref='services'>
    <Services/>
    </div>
    <div ref='team'>
    <Team/>
    </div>
    <div ref='blog'>
    <More/>
    </div>
    <Footer />
  </v-sheet>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import About from '@/components/About.vue'
import Services from '@/components/Services.vue'
import Team from '@/components/Team.vue'
import More from '@/components/More.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Toolbar,
    About,
    Services,
    Team,
    More,
    Footer
  },
  methods: {
    scrollToRef(link) {
      this.$refs[link].scrollIntoView({ block: 'end',  behavior: 'smooth' })
    } 
  }
}
</script>