<template>
  <v-container class="py-4 py-lg-8">
    <div style="width: 80px; height: 4px" class="mb-3 secondary" />
    <h2 class="mb-3 font-weight-medium">Meet our core team</h2>
    <div class="text-h6 text-lg-h6 mt-3 font-weight-light">
        We are a team from San Francisco, New York, and Washinton D.C. with extensive experience developing large-scale enterprise services and a common passion for blockchain.</div>
    <v-card class="pa-4 mt-6">
      <v-row>
        <v-col
          v-for="(item, i) in team"
          :key="i"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
        >
          <div class="d-flex align-center">
            <div class="mr-4">
              <v-avatar size="100">
                <v-icon size="50">mdi-account</v-icon>
                <!-- <v-img :src="item.image"></v-img> -->
              </v-avatar>
            </div>
            <div>
              <div class="text-h6">{{ item.name }}</div>
              <div class="font-weight-regular ">{{ item.title }}</div>
            <div class="font-weight-regular ">{{ item.description }}</div>
            <div class="font-weight-regular ">{{ item.education }}</div>

            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      team: [{
        name: 'Frederick',
        title: 'Software Engineer/Data Scientist',
        description: 'ML/AI, Data Products',
        education: 'Financial Engineering from Columbia University',
        image: '/images/team/1.jpeg',
        twitter: '#',
        github: '#',
        linkedin: '#'
      }, {
        name: 'Amy',
        title: 'Software Engineer',
        description: 'Backend Development, Asset Management',
        education: 'Mathematics from Columbia University',
        image: '/images/team/2.jpeg',
        twitter: '#',
        github: '#',
        linkedin: '#'
      }, {
        name: 'Ryan',
        title: 'Data Engineer/Software Engineer',
        description: 'Big Data, Consulting',
        education: 'Industrial Engineering from Columbia University',
        image: '/images/team/4.jpeg',
        twitter: '#',
        github: '#',
        linkedin: '#'
      },
      {
        name: 'Work with us',
        image: '/images/team/8.jpeg',
        twitter: '#',
        github: '#',
        linkedin: '#'
      }
      ]
    }
  }
}
</script>
