<template>
  <TeamCards />
</template>

<script>
import TeamCards from '@/components/TeamCards.vue'

export default {
  components: {
    TeamCards
  }
}
</script>
