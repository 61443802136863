export const menu2 = [{
  title: 'About',
  link: 'about'
}, {
  title: 'Services',
  link: 'services'
}, {
  title: 'Team',
  link: 'team'
}, {
  title: 'Connect',
  link: 'blog'
}]

export default {
  menu2
}
